import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class ctaTwoCol extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol lg="7" md="12">
          <h2 className="font-alt font-w-700 text-white title-xs-medium title-large">{this.props.title}</h2>
          <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta">{this.props.description}</p>

          <div className="btn-toolbar">
            {this.props.link ? (
              this.props.link.charAt(0) == '/' ? (
                <Link to={this.props.link} className="nav-link btn btn-cta-color btn-sm-block mt-4" aria-label={this.props.title}>
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.linktext}
                </Link>
              ) : (
                <a href={this.props.link} className="nav-link btn btn-cta-color btn-sm-block mt-4" target="_blank" rel="noopener" aria-label={this.props.title}>
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.linktext}
                </a>
              )
            ) : null}

            {this.props.secondlink ? (
              this.props.secondlink.charAt(0) == '/' ? (
                <Link to={this.props.secondlink} className="nav-link btn btn-cta-color btn-sm-block mt-4" aria-label={this.props.secondtitle}>
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.secondlinktext}
                </Link>
              ) : (
                <a href={this.props.secondlink} className="nav-link btn btn-cta-color btn-sm-block mt-4" target="_blank" rel="noopener" aria-label={this.props.secondtitle}>
                  <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                  {this.props.secondlinktext}
                </a>
              )
            ) : null}
          </div>
        </MDBCol>
        <MDBCol lg="5" md="12" className="text-center image-center mt-md-4 d-none d-lg-block">
          <Img fluid={this.props.image} alt={this.props.alttext} className="alt-bg-image rounded w-850" />
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ctaTwoCol
